import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';
import Loader from '../components/block-components/loader/loader';
import { routeConstants } from '../services/constants/route-constants';
import ProctedRoutes from './protected-routes';


const AdminRoutes = lazy(() => import("./admin-route"));
const StudentRoutes = lazy(() => import("./student-route"));
const ManagerRoutes = lazy(() => import("./manager-route"));
const SchoolManagerRoutes = lazy(() => import("./school-manager-route"));
const SchoolOfficialRoutes = lazy(() => import("./school-official-route"));
const FinanceManagerRoutes = lazy(() => import("./finance-manager-route"));
const HomeRoutes = lazy(() => import("./home-route"));

function BaseRoute() {
  return (
    <Router>
      <Suspense fallback={<Loader/>}>
        <Routes>
          <Route element={<ProctedRoutes/>}>
            <Route path={`${routeConstants.admin}/*`} element={<AdminRoutes/>}></Route>
            <Route path={`${routeConstants.manager}/*`} element={<ManagerRoutes/>}></Route>
            <Route path={`${routeConstants.school_manager}/*`} element={<SchoolManagerRoutes/>}></Route>
            <Route path={`${routeConstants.school_official}/*`} element={<SchoolOfficialRoutes/>}></Route>
            <Route path={`${routeConstants.finance_manager}/*`} element={<FinanceManagerRoutes/>}></Route>
            <Route path={`${routeConstants.student}/*`} element={<StudentRoutes/>}></Route>
          </Route>
          <Route path={'*'} element={<HomeRoutes/>}></Route>
        </Routes>
      </Suspense>
    </Router>
  );
}

export default BaseRoute;
